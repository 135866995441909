<template>
  <div class="preview">
   <detail :is_preview="1"></detail>
  </div>
</template>

<script>
import detail from '../collect/detail.vue'
export default {
  components:{
    detail
  }
}
</script>

<style>

</style>